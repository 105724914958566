import React, { useState, useEffect, useContext } from "react";
import { Typography, Paper, Grid, CssBaseline } from "@material-ui/core";
import useStyles from "../../styles";
import { styled } from "@mui/system";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { LoadingOutlined } from "@ant-design/icons";
import Badge from "@mui/material/Badge";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ApprovedRequeststable from "./ApprovalRequeststable";
import {
  ArrowRightAlt,
  CloudDownloadOutlined,
  FindReplace,
} from "@material-ui/icons";
import Loading from "../../../../contextAPI/Loading";
import { fetchSocietyId } from "../../../../contextAPI/fetchSociety";
import * as api from "../../../../api";
import moment from "moment";
import { PersonSearch } from "@mui/icons-material";
import Chip from "@mui/material/Chip";
import { resolveErrorMessage } from "../../../../services/service";
import { navigateToResident } from "../../../../Navigation/Navigations";
import AddressFilterModal from "../../../../GlobalComponents/AddressFilterModal/AddressFilterModal";
import { AuthContext } from "../../../../contextAPI/context";
import { handleGetCheckModulePermission } from "../../../../contextAPI/checkModulesPermission";
import { ExportJsonCsv } from "react-export-json-csv";
import { residentCsvHeader } from "../../../../GlobalFunctions/Const-Values";
import { toast } from "react-toastify";
import ApiCall from "../../../../api/ApiCall";
import { AllResidentRoutes } from "../../../../api/Routes";
import VendorApproveRequest from "./VendorApproveRequest";
import MakeManagerModal from "./MakeManagerModal";
import ConfirmModal from "../../../../contextAPI/ConfirmModal";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3d3fb8",
  500: "#007FFF",
  600: "#343cac",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
  1000: "#2f45c5",
  1100: "#2e3094",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${blue[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabUnstyledClasses.selected} {
    background-color: #fff;
    color: ${blue[600]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabsList = styled(TabsListUnstyled)(
  ({ theme }) => `
  background-color: ${blue[1100]};
  min-width: 60vw;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  `
);

function ApprovedRequests() {
  const classes = useStyles();
  const { authState } = useContext(AuthContext);
  const { user, usermoduleswithpermissions, plant } = authState;
  const [selectableHeader, setselectableHeader] = useState("All");
  const [loader, setloader] = useState(false);
  const [loader2, setloader2] = useState(false);
  const [loader3, setloader3] = useState(false);

  const navigate = useNavigate();
  const [count, setCount] = React.useState(0);
  const [page, setPage] = useState(1);
  const [search, setsearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  var date = moment(new Date()).format("YYYY-MM-DD");
  var newDate = moment(new Date()).add(-3, "months").format("YYYY-MM-DD");
  const [allApprovalRequests, setallApprovalRequests] = useState([]);
  const [pendingUserCount, setpendingUserCount] = useState(0);
  const [statusForResidentData, setstatusForResidentData] =
    useState("approved");
  const [approveduserCount, setapproveduserCount] = useState(0);
  const [isReset, setisReset] = useState(false);
  const [allApprovedVendor, setAllApprovedVendor] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");
  const [csvData, setcsvData] = useState([]);
  const accountHeader = [
    "All",
    "Owner",
    plant ? "Employee" : "Tenant",
    "Vendor",
  ];

  // ADDRESS FILTER MODAL
  const [isOpenAddressFilterModal, setisOpenAddressFilterModal] =
    useState(false);
  const [property, setproperty] = useState("Select Property");
  const [floor, setfloor] = useState("Select Floor");
  const [flat, setflat] = useState("Select Flat");
  const [isReadWrite, setIsReadWrite] = useState(false);

  // Bulk Manager
  const [checkedManager, setcheckedManager] = useState({});
  const [showCheckBox, setshowCheckBox] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [addManager, setaddManager] = useState(false);
  const [removeManager, setremoveManager] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("SelectGroup");
  const [managerData, setmanagerData] = useState([]);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const totalSelectedMangers = Object?.values(checkedManager)?.reduce(
    (total, array) => total + array?.length,
    0
  );

  const rejectConfirmation = () => {
    setshowCheckBox(false);
    setaddManager(false);
    setcheckedManager({});
    setSelectAllChecked(false);
    setremoveManager(false);
    setSelectedGroup("SelectGroup");
    seterrorMessage("");
  };

  //Make Manager
  const handleMakeManger = async () => {
    setloader2(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    const data = {
      userId: managerData?._id,
      addressGroupId: selectedGroup,
      societyId: fetchSocietyId(),
    };
    const res = await ApiCall("post", AllResidentRoutes.makeManager, data);
    if (res?.success) {
      rejectConfirmation();
      getAllApprovedRequests();
      toast.success("Manager assigned successfully!");
      setloader2(false);
    } else {
      seterrorMessage(res?.error);
      setloader2(false);
    }
  };
  //Remove Manager
  const handleRemoveManger = async () => {
    setloader2(true);
    seterrorMessage("");
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    const data = {
      userId: managerData?._id,
      addressGroupId: managerData?.managerSocieties?.[0]?.addressGroupId,
    };
    const res = await ApiCall("post", AllResidentRoutes.removeManager, data);
    if (res?.success) {
      rejectConfirmation();
      getAllApprovedRequests();
      toast.success("Manager removed successfully!");
      setloader2(false);
    } else {
      seterrorMessage(res?.error);
      setloader2(false);
    }
  };

  useEffect(async () => {
    if (usermoduleswithpermissions?.length) {
      setIsReadWrite(
        await handleGetCheckModulePermission(
          usermoduleswithpermissions,
          "Resident"
        )
      );
    }
  }, [usermoduleswithpermissions]);

  const handleChange = (event, newValue) => {
    setPage(1);
    setselectableHeader(newValue);
  };

  const createCsvData = (allResidents) => {
    setloader3(true);
    let temp = [];
    allResidents?.map((resident, index) => {
      return temp.push({
        index: index + 1,
        sector: resident?.property?.sector,
        propertyType: resident?.property?.type,
        unit: resident?.property?.name?.toLocaleLowerCase()?.includes("road")
          ? resident?.flat?.number
          : resident?.flat?.name,
        customer: resident?.name,
        employeeID: resident?.empId ? resident?.empId : "-",
        address: `${resident?.property?.name} - ${resident?.floor?.name} - ${resident?.flat?.number}`,
        signUpDate: moment(resident?.createdAt).format("DD/MM/YYYY"),
        signUpTime: moment(resident?.createdAt).format("h:mm A"),
      });
    });
    setcsvData([...temp]);
    setloader3(false);
  };

  const getAllApprovedRequests = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : date,
        property: property === "Select Property" ? "" : property?._id,
        role:
          selectableHeader === "All"
            ? ""
            : selectableHeader === "Employee"
            ? "tenant"
            : selectableHeader.toLocaleLowerCase(),
        floor: floor === "Select Floor" ? "" : floor?._id,
        flat: flat === "Select Flat" ? "" : flat?._id,
        searchKey: search,
        pageNumber: page,
        pageLimit: 50,
        status: "approved",
        resident: "true",
      };
      if (selectableHeader === "Manager") {
        sendData.isApproved = true;
        delete sendData.resident;
      }
      const res = await api.getAllResidentRequests(sendData);
      if (res?.data?.success) {
        setisReset(false);
        setCount(res.data?.user?.count);
        setapproveduserCount(res.data?.user?.approvedUser);
        setpendingUserCount(res.data?.user?.pendingUser);
        setallApprovalRequests(res.data?.user?.user);
        createCsvData(res.data?.user?.user);
        setloader(false);
      }
    } catch (error) {
      setloader(false);
      resolveErrorMessage(error);
    }
  };

  const handleGetApprovedVendor = async () => {
    setloader(true);
    setTimeout(() => {
      setloader(false);
    }, 40000);
    const data = {
      filters: {
        approvalStatus: "approved",
        society: fetchSocietyId(),
      },
      page: page,
      limit: 50,
    };
    const res = await ApiCall(
      "post",
      AllResidentRoutes.getAllVendorRequests,
      data
    );
    if (res?.success) {
      setAllApprovedVendor(res?.count?.data);
      setCount(res?.count?.totalPages);
      setloader(false);
    } else {
      setloader(false);
    }
  };

  useEffect(() => {
    if (selectableHeader === "Vendor") {
      handleGetApprovedVendor();
    }
  }, [page, selectableHeader]);

  const getResidentCsvData = async () => {
    setloader2(true);
    setTimeout(() => {
      setloader2(false);
    }, 40000);
    try {
      let sendData = {
        societyId: fetchSocietyId(),
        startDate: startDate ? startDate : "",
        endDate: endDate ? endDate : date,
        property: property === "Select Property" ? "" : property?._id,
        role:
          selectableHeader === "All"
            ? ""
            : selectableHeader.toLocaleLowerCase(),
        floor: floor === "Select Floor" ? "" : floor?._id,
        flat: flat === "Select Flat" ? "" : flat?._id,
        searchKey: search,
        status: "approved",
        resident: "true",
        pageNumber: page,
      };
      const res = await api.getResidentCSV(sendData);
      if (res?.data?.success) {
        setisReset(false);
        createCsvData(res.data?.user?.user);
        setloader2(false);
      }
    } catch (error) {
      setloader2(false);
      resolveErrorMessage(error);
    }
  };

  const handleReset = () => {
    setsearch("");
    setStartDate("");
    setEndDate("");
    setPage(1);
    setproperty("Select Property");
    setfloor("Select Floor");
    setflat("Select Flat");
    setisReset(true);
  };

  useEffect(() => {
    if (selectableHeader !== "Vendor") {
      getAllApprovedRequests();
    }
  }, [
    page,
    search,
    selectableHeader,
    startDate,
    statusForResidentData,
    isReset,
    property,
    floor,
    flat,
  ]);

  useEffect(() => {
    getResidentCsvData();
  }, []);

  const handleOpenFilterByAddressModal = () => {
    setisOpenAddressFilterModal(true);
  };

  const removeStartDate = () => {
    setStartDate("");
  };
  const removeEndDate = () => {
    setEndDate("");
    getAllApprovedRequests();
  };
  const removeProperty = () => {
    setproperty("Select Property");
  };
  const removeFloor = () => {
    setfloor("Select Floor");
  };
  const removeFlat = () => {
    setflat("Select Flat");
  };
  return (
    <>
      <div
        className={classes.root}
        style={{
          opacity: loader ? 0.4 : "",
          pointerEvents: loader ? "none" : "",
          overflow: loader ? "hidden" : "auto",
          height: "100vh",
        }}
      >
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} sm={12}>
              <Paper style={{ padding: 12, boxShadow: "0px 0px 0px" }}>
                <Grid
                  container
                  style={{ justifyContent: "space-between", marginTop: 8 }}
                >
                  <Grid
                    container
                    item
                    xs={4}
                    lg={4}
                    sm={4}
                    style={{ justifyContent: "space-between" }}
                  >
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: 22,
                        fontFamily: "Montserrat, sans-serif",
                        color: "#6c757d",
                        marginTop: "-5px",
                        marginBottom: 10,
                      }}
                    >
                      {plant ? "Employee" : "Resident"} List &nbsp;{" "}
                      <Badge
                        badgeContent={
                          selectableHeader === "Vendor"
                            ? allApprovedVendor?.length
                            : approveduserCount
                        }
                        color="success"
                        style={{ marginTop: -3 }}
                      ></Badge>
                      &nbsp; &nbsp;
                      {!loader ? (
                        <RefreshIcon
                          title="refresh request Page"
                          onClick={() => {
                            if (selectableHeader === "Vendor") {
                              handleGetApprovedVendor();
                            } else {
                              getAllApprovedRequests();
                              getResidentCsvData();
                            }
                          }}
                          style={{
                            marginLeft: 5,
                            marginTop: -4,
                            cursor: "pointer",
                            color: "#000",
                          }}
                        />
                      ) : (
                        <LoadingOutlined
                          title="laoding..."
                          style={{
                            marginLeft: 5,
                            color: "#000",
                            marginTop: -4,
                          }}
                        />
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    lg={8}
                    sm={8}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div
                      class="box"
                      style={{
                        minWidth: "250px",
                        marginLeft: 10,
                        border: "1px solid #E1E1E1",
                        borderRadius: 10,
                      }}
                    >
                      <input
                        onChange={(e) => {
                          setsearch(e.target.value);
                          setPage(1);
                        }}
                        value={search}
                        type="text"
                        placeholder="search..."
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            if (search === "") {
                              toast.error("Please enter search input first");
                            } else {
                              getAllApprovedRequests();
                            }
                          }
                        }}
                      />
                      <div className="searchIcon">
                        <SearchIcon
                          onClick={() => {
                            if (search === "") {
                              toast.error("Please enter search input first");
                            } else {
                              getAllApprovedRequests();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="card"
                      style={{
                        height: 30,
                        boxShadow: "0 3px 6px #4a6078",
                        width: "170px",
                        backgroundColor: "#2f45c5",
                        color: "#ffffff",
                        cursor: "pointer",
                        marginTop: "5px",
                      }}
                      onClick={() => {
                        navigate(navigateToResident({ status: "pending" }));
                      }}
                    >
                      <div class="card-body ">
                        <p
                          className="card-text"
                          style={{
                            fontSize: "11px",
                            fontWeight: "bold",
                            fontFamily: "Montserrat, sans-serif",
                            marginTop: "-12px",
                            marginBottom: "20px",
                            textAlign: "center",
                          }}
                        >
                          {/* <Badge
                            badgeContent={pendingUserCount}
                            color="warning"
                            style={{ marginTop: -3 }}
                          ></Badge>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                          REQUESTS&nbsp;&nbsp; &nbsp;
                          <ArrowRightAlt
                            style={{
                              marginLeft: 5,
                              fontSize: "15px",
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: 25, justifyContent: "space-between" }}
                >
                  <Grid style={{ marginBottom: 15 }}>
                    <Grid container>
                      <div
                        className="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "180px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginLeft: 5,
                          marginRight: 6,
                        }}
                        onClick={handleOpenFilterByAddressModal}
                      >
                        <div class="card-body ">
                          <p
                            className="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-14px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            Filter By Address &nbsp;
                            <PersonSearch />
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          pointerEvents: loader2 ? "none" : "auto",
                          opacity: loader2 ? 0.5 : 1,
                        }}
                      >
                        <ExportJsonCsv
                          fileTitle={`${date} Residents`}
                          headers={residentCsvHeader}
                          items={csvData}
                          style={{
                            backgroundColor: "#2f45c5",
                            border: "none",
                            boxShadow: "0 3px 6px #4a6078",
                            height: 30,
                            width: "170px",
                            color: "#fff",
                            marginRight: 5,
                            padding: 6,
                            borderRadius: 5,
                            paddingLeft: 21,
                            paddingRight: 21,
                            cursor: "pointer",
                            justifyContent: "flex-end",
                            fontFamily: "Montserrat, sans-serif",
                            fontSize: "11px",
                            fontWeight: "bold",
                            marginLeft: 5,
                          }}
                        >
                          Download CSV
                          {loader2 ? (
                            <LoadingOutlined
                              style={{ marginLeft: 6, fontSize: "20px" }}
                            />
                          ) : (
                            <CloudDownloadOutlined
                              style={{ marginLeft: 6, fontSize: "20px" }}
                            />
                          )}
                        </ExportJsonCsv>
                      </div>

                      {/* <div
                        className="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "180px",
                          backgroundColor: showCheckBox ? "#696969" : "#2f45c5",
                          color: "#f8f9fa",
                          cursor: "pointer",
                          marginLeft: 5,
                          marginRight: 6,
                        }}
                        onClick={() => {
                          setshowCheckBox(!showCheckBox);
                          setcheckedManager({});
                        }}
                      >
                        <div className="card-body">
                          <p
                            className="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-14px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            {showCheckBox ? "Cancel Process" : "Bulk Manager"}
                          </p>
                        </div>
                      </div> */}

                      <div
                        className="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "120px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleReset();
                        }}
                      >
                        <div class="card-body ">
                          <p
                            className="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-14px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            RESET
                            <FindReplace />
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid container>
                      <Typography
                        style={{
                          color: "#283f59",
                          fontSize: 12,
                          marginRight: 8,
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                          marginTop: 12,
                        }}
                      >
                        Start Date
                      </Typography>
                      <input
                        type="date"
                        style={{
                          borderRadius: 10,
                          padding: 5,
                          width: 120,
                          height: 40,
                          border: "1px solid #416082",
                          outline: 0,
                        }}
                        onChange={(event) => {
                          setPage(1);
                          setStartDate(event.target.value);
                        }}
                        placeholder="Choose Start Date"
                        max={date}
                        value={startDate === "" ? newDate : startDate}
                      />

                      <Typography
                        style={{
                          color: "#283f59",
                          fontSize: 12,
                          marginTop: 12,
                          marginRight: 8,
                          marginLeft: 10,
                          fontWeight: "bold",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        End Date
                      </Typography>
                      <input
                        type="date"
                        style={{
                          borderRadius: 10,
                          padding: 5,
                          width: 120,
                          height: 40,
                          border: "1px solid #416082",
                          outline: 0,
                        }}
                        min={startDate}
                        max={date}
                        disabled={!startDate}
                        onChange={(event) => {
                          setPage(1);
                          setEndDate(event.target.value);
                        }}
                        placeholder="Choose End Date"
                        value={endDate === "" ? date : endDate}
                      />
                      <div
                        className="card"
                        style={{
                          height: 30,
                          boxShadow: "0 3px 6px #4a6078",
                          width: "80px",
                          backgroundColor: "#2f45c5",
                          color: "#ffffff",
                          cursor: "pointer",
                          marginLeft: 5,
                          marginTop: 3,
                        }}
                        onClick={() => {
                          getAllApprovedRequests();
                          setPage(1);
                        }}
                      >
                        <div class="card-body ">
                          <p
                            className="card-text"
                            style={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontFamily: "Montserrat, sans-serif",
                              marginTop: "-14px",
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                          >
                            <PersonSearch />
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container>
                  {showCheckBox ? (
                    totalSelectedMangers === 0 ? (
                      <span className="text-danger ms-3 mt-1">
                        Please Select at least one resident
                      </span>
                    ) : (
                      <div className="text-primary d-flex align-items-center gap-2 ms-3">
                        Selected {totalSelectedMangers} resident
                        {totalSelectedMangers > 1 ? "s" : ""}
                        <span
                          className="btn btn-sm btn-rounded btn-primary"
                          onClick={() => {
                            setaddManager(true);
                          }}
                        >
                          Make Manager
                        </span>
                      </div>
                    )
                  ) : null}
                </Grid>
                <Grid
                  container
                  style={{ justifyContent: "flex-end", marginTop: 10 }}
                >
                  {startDate && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={startDate}
                        onDelete={removeStartDate}
                      />
                    </Typography>
                  )}
                  {endDate && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={endDate}
                        onDelete={removeEndDate}
                      />
                    </Typography>
                  )}
                  {property !== "Select Property" && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={property?.name}
                        onDelete={removeProperty}
                      />
                    </Typography>
                  )}
                  {floor !== "Select Floor" && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={floor?.name}
                        onDelete={removeFloor}
                      />
                    </Typography>
                  )}
                  {flat !== "Select Flat" && (
                    <Typography
                      style={{
                        color: "#283f59",
                        fontSize: 14,
                        marginLeft: 6,
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      <Chip
                        style={{
                          backgroundColor: "rgba(54, 162, 235, 0.2)",
                        }}
                        label={flat?.name}
                        onDelete={removeFlat}
                      />
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  md={12}
                  container
                  style={{ marginTop: 18, justifyContent: "center" }}
                >
                  <TabsUnstyled
                    value={selectableHeader}
                    onChange={handleChange}
                  >
                    <TabsList value={selectableHeader}>
                      {accountHeader?.map((tb) => {
                        return <Tab value={tb}>{tb}</Tab>;
                      })}
                    </TabsList>
                  </TabsUnstyled>
                </Grid>
                {allApprovalRequests?.length > 0 &&
                  selectableHeader !== "Vendor" && (
                    <Grid container style={{ marginTop: 7 }}>
                      <ApprovedRequeststable
                        allApprovalRequests={allApprovalRequests}
                        page={page}
                        isReadWrite={isReadWrite}
                        user={user}
                        getAllApprovedRequests={getAllApprovedRequests}
                        setaddManager={setaddManager}
                        setremoveManager={setremoveManager}
                        showCheckBox={showCheckBox}
                        checkedManager={checkedManager}
                        setcheckedManager={setcheckedManager}
                        selectAllChecked={selectAllChecked}
                        setSelectAllChecked={setSelectAllChecked}
                        selectableHeader={selectableHeader}
                        setmanagerData={setmanagerData}
                        plant={plant}
                      />
                    </Grid>
                  )}

                {allApprovedVendor?.length > 0 &&
                  selectableHeader === "Vendor" && (
                    <Grid container style={{ marginTop: 7 }}>
                      <VendorApproveRequest
                        allApprovedVendor={allApprovedVendor}
                        page={page}
                      />
                    </Grid>
                  )}

                {selectableHeader === "Vendor"
                  ? allApprovedVendor?.length > 0 &&
                    !loader && (
                      <Grid
                        container
                        style={{
                          justifyContent: "center",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <Pagination
                          className={classes.pagination}
                          count={count}
                          color="primary"
                          page={page}
                          onChange={handlePageChange}
                        />
                      </Grid>
                    )
                  : allApprovalRequests?.length > 0 &&
                    !loader && (
                      <Grid
                        container
                        style={{
                          justifyContent: "center",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <Pagination
                          className={classes.pagination}
                          count={count}
                          color="primary"
                          page={page}
                          onChange={handlePageChange}
                        />
                      </Grid>
                    )}
                {!loader &&
                  allApprovalRequests?.length === 0 &&
                  selectableHeader !== "Vendor" && (
                    <Typography
                      style={{
                        color: "#6c757d",
                        textAlign: "center",
                        fontSize: 22,
                        marginTop: 30,
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {statusForResidentData === "approved"
                        ? selectableHeader === "Manager"
                          ? "No Approved Managers"
                          : "No Approved Residents"
                        : "No Rejected Residents"}
                    </Typography>
                  )}
                {!loader &&
                  allApprovedVendor?.length === 0 &&
                  selectableHeader === "Vendor" && (
                    <Typography
                      style={{
                        color: "#6c757d",
                        textAlign: "center",
                        fontSize: 22,
                        marginTop: 30,
                        fontWeight: "bold",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      {statusForResidentData === "approved"
                        ? "No Approved Vendor"
                        : "No Rejected Vendor"}
                    </Typography>
                  )}
              </Paper>
            </Grid>
          </Grid>
        </main>
      </div>
      {loader && <Loading loader={loader} />}

      <AddressFilterModal
        isSector={false}
        isLocal={false}
        isOpenAddressFilterModal={isOpenAddressFilterModal}
        setisOpenAddressFilterModal={setisOpenAddressFilterModal}
        property={property}
        setproperty={setproperty}
        floor={floor}
        setfloor={setfloor}
        flat={flat}
        setflat={setflat}
        handleFilterByAddress={getAllApprovedRequests}
      />
      {addManager && (
        <MakeManagerModal
          addManager={addManager}
          setaddManager={setaddManager}
          selectedGroup={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          handleMakeManger={handleMakeManger}
          errorMessage={errorMessage}
          loader2={loader2}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {removeManager && (
        <ConfirmModal
          title={`${"ARE YOU SURE YOU WANT TO REMOVE SELECTED USER FROM MANAGER"}`}
          description="You can confirm by clicking below."
          loader={loader2}
          handleConfirmation={handleRemoveManger}
          rejectConfirmation={rejectConfirmation}
          isOpen={removeManager}
          errorMsg={errorMessage}
        />
      )}
    </>
  );
}

export default ApprovedRequests;
