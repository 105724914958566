import React from "react";
import { Typography, Grid, Paper, Checkbox } from "@material-ui/core";
import useStyles from "../styles";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { navigateToComplaintDetails } from "../../../Navigation/Navigations";
import { getStatusClass } from "../../../GlobalFunctions/GlobalFunctions";

function AllComplaintsTable(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    allComplaints,
    page,
    complaintType,
    isMultiplePrint,
    selectedComplaintForPrint,
    setselectedComplaintForPrint,
    plant,
  } = props;
  const localPageNumber = parseInt(localStorage.getItem("pageNumber"));

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        style={{ tableLayout: "fixed" }}
      >
        <TableHead className="text-nowrap">
          <TableRow container>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {isMultiplePrint ? "Select" : "Sr."}
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              No.
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Title
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Time
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Complainer
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Address
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Category
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
                fontSize: "10px",
              }}
            >
              Subcategory
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Deadline
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Priority
            </TableCell>
            <TableCell
              align="center"
              className={classes.tableHeaderCell}
              sx={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        {allComplaints?.map((complaint, index) => {
          var date = moment(complaint.createdAt).format("DD/MM/YYYY - h:mm A");
          return (
            <TableBody>
              {isMultiplePrint ? (
                <TableCell
                  align="center"
                  sx={{
                    color: "black",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={selectedComplaintForPrint?.includes(complaint._id)}
                    onClick={() => {
                      if (selectedComplaintForPrint?.includes(complaint._id)) {
                        selectedComplaintForPrint.splice(
                          selectedComplaintForPrint.indexOf(complaint._id),
                          1
                        );
                        setselectedComplaintForPrint([
                          ...selectedComplaintForPrint,
                        ]);
                      } else {
                        setselectedComplaintForPrint([
                          ...selectedComplaintForPrint,
                          complaint._id,
                        ]);
                      }
                    }}
                    style={{ color: "#2f45c5" }}
                  />
                </TableCell>
              ) : (
                <TableCell
                  align="center"
                  sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
                >
                  {(localPageNumber - 1) * 50 + (index + 1)}.
                </TableCell>
              )}
              <TableCell
                // align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                <Grid container style={{ marginLeft: -25, width: 140 }}>
                  {" "}
                  <Typography
                    style={{
                      textDecoration: "underline",
                      fontSize: 13,
                      cursor: "pointer",
                      color: "#42628a",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      navigate(
                        navigateToComplaintDetails({
                          complaintType,
                          complaintId: complaint?._id,
                        })
                      );
                    }}
                  >
                    {complaint.complaintNumber}
                  </Typography>
                  <CopyToClipboard
                    text={complaint.complaintNumber}
                    onCopy={() => {
                      toast.success("Copy to Clipboard", {
                        position: "top-right",
                        autoClose: 500,
                        hideProgressBar: false,
                        closeOnClick: true,
                      });
                    }}
                  >
                    <ContentCopy
                      style={{
                        fontSize: 12,
                        marginTop: 3,
                        marginLeft: 3,
                        color: "#5a80ad",
                        cursor: "pointer",
                      }}
                    />
                  </CopyToClipboard>
                </Grid>
              </TableCell>
              <TableCell
                title={complaint?.complaintTitle}
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {complaint.complaintTitle?.length > 35
                  ? complaint.complaintTitle.slice(0, 35).concat("...")
                  : complaint.complaintTitle}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                {date}
              </TableCell>
              <TableCell
                align="center"
                sx={{ color: "black", fontFamily: "Poppins, sans-serif" }}
              >
                <p>
                  {complaint?.complainer?.name}
                  {/* <span
                    style={{
                      padding: 5,
                      textTransform: "capitalize",
                      marginLeft: 5,
                    }}
                    class={
                      complaint?.creatorRole === "admin" 
                        ? "badge bg-primary"
                        : "badge bg-info"
                    }
                  >
                    {complaint?.creatorRole?complaint?.creatorRole:complaint?.complainer?.role}
                  </span> */}
                </p>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  cursor: "pointer",
                }}
              >
                {complaint?.property?.name}
                <br />
                {complaint?.floor?.name}
                <br />
                {complaint?.flat?.number}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  cursor: "pointer",
                }}
              >
                {complaint?.category?.categoryName}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  cursor: "pointer",
                }}
              >
                {complaint?.subcomplaintCategory?.subcategoryName}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  cursor: "pointer",
                }}
              >
                {complaint?.finalDeadline
                  ? moment(complaint.finalDeadline).format(
                      "DD/MM/YYYY - h:mm A"
                    )
                  : "-"}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  color: "black",
                  fontFamily: "Poppins, sans-serif",
                  cursor: "pointer",
                }}
              >
                <span
                  style={{
                    // width: "55px",
                    padding: 7,
                    fontSize: 12,
                    textTransform: "uppercase",
                    color: complaint.priority === "low" ? "#F31D2F" : "#3862DD",
                  }}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.66602 18.6665H25.3327L19.3327 12.6665L25.3327 6.6665H6.66602V27.9998"
                      stroke={
                        complaint.priority === "low" ? "#F31D2F" : "#3862DD"
                      }
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {complaint.priority}
                </span>
              </TableCell>

              <TableCell
                align="center"
                sx={{
                  color: "black",
                  fontFamily: "Poppins",
                  cursor: "pointer",
                }}
              >
                <span
                  className={`complaint-status ${getStatusClass(
                    complaint?.status
                  )}`}
                >
                  {complaint?.status}
                </span>
              </TableCell>
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
}
export default AllComplaintsTable;
