import React from "react";
import {
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Modal from "@mui/material/Modal";

const UpdateLifecycleModal = (props) => {
  const {
    updateProcecssModal,
    classes,
    setselectProcess,
    setupdateProcecssModal,
    complaint,
    selectProcess,
    isPlant,
  } = props;

  return (
    <Modal open={updateProcecssModal}>
      <Paper
        style={{
          backgroundColor: "#e6e7f0",
          boxShadow: "2px 2px 8px #000",
          borderRadius: 7,
          minWidth: "350px",
          maxWidth: "40vw",
        }}
        className={classes.ModalView}
      >
        <Grid
          container
          style={{
            textAlign: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#000",
              textAlign: "center",
              fontWeight: "normal",
              fontFamily: "Montserrat, sans-serif",
              marginLeft: 10,
              fontSize: 17,
              opacity: 1,
              fontWeight: 400,
            }}
          >
            Update Process
          </Typography>

          <IconButton
            onClick={() => {
              setupdateProcecssModal(false);
              setselectProcess("Select Process");
            }}
          >
            <Close style={{ color: "#000", fontSize: 20, marginTop: -10 }} />
          </IconButton>
        </Grid>
        <Divider
          style={{
            marginTop: 0,
            marginBottom: 10,
            backgroundColor: "#000",
          }}
        />
        <Grid container style={{ justifyContent: "center" }}>
          <Select
            value={selectProcess}
            onChange={(event) => {
              setselectProcess(event.target.value);
            }}
            style={{
              display: "block",
              color: "#343a40",
              fontFamily: "Montserrat, sans-serif",
              marginTop: 10,
              backgroundColor: "#fff",
              borderRadius: 6,
              fontSize: 16,
              width: "70%",
              padding: 7,
              paddingLeft: 20,
            }}
          >
            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              selected
              value="Select Process"
            >
              Select Process
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={
                complaint?.vendor ||
                complaint?.status === "resolved" ||
                complaint?.status === "closed" ||
                complaint?.status === "cancel"
              }
              selected
              value="Assign Vendor"
            >
              1. Assign Vendor
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={
                !complaint?.vendor ||
                complaint?.status === "resolved" ||
                complaint?.status === "closed" ||
                complaint?.status === "cancel"
              }
              selected
              value="Re-assign Vendor"
            >
              2. Re-assign Vendor
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={
                !complaint?.vendor ||
                complaint?.status === "resolved" ||
                complaint?.status === "closed" ||
                complaint?.status === "cancel"
              }
              selected
              value="Sub Complaint"
            >
              3. Sub Complaint
            </MenuItem>

            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={
                !complaint?.vendor ||
                complaint?.status === "resolved" ||
                complaint?.status === "closed" ||
                complaint?.status === "cancel"
              }
              selected
              value="Vendor Visit"
            >
              4. Vendor Visit
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={
                !complaint?.vendor ||
                complaint?.status === "closed" ||
                complaint?.status === "cancel"
              }
              selected
              value="Notify Vendor"
            >
              5. Notify Vendor
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={
                complaint?.status === "resolved" ||
                complaint?.status === "closed" ||
                complaint?.status === "cancel"
              }
              selected
              value="Change Priority"
            >
              6. Change Priority
            </MenuItem>
            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={
                complaint?.status === "closed" || complaint?.status === "cancel"
              }
              selected
              value="Notify Complainer"
            >
              7. Notify Complainer
            </MenuItem>

            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={
                  !complaint?.vendor ||
                complaint?.status === "closed" || complaint?.status === "cancel"
              }
              value="Close Complaint"
            >
              8. Close Complaint
            </MenuItem>
            {!isPlant && (
              <MenuItem
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                }}
                disabled={
                  complaint?.status === "resolved" ||
                  complaint?.status === "closed" ||
                  complaint?.status === "cancel"
                }
                selected
                value="Mark As Resolved"
              >
                9. Mark As Resolved
              </MenuItem>
            )}
            {localStorage.getItem("Reopen") === "true" && (
              <MenuItem
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                }}
                disabled={complaint?.status !== "closed"}
                selected
                value="Re-open Complaint"
              >
                {!isPlant ? 10 : 9}. Re-open Complaint
              </MenuItem>
            )}
            <MenuItem
              style={{
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
              disabled={complaint?.status === "cancel"}
              value="Cancel Complaint"
            >
              {localStorage.getItem("Reopen") === "true" && !isPlant ? 11 : 10}.
              Cancel Complaint
            </MenuItem>
            {isPlant && (
              <MenuItem
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                }}
                disabled={
                  complaint?.status === "resolved" ||
                  complaint?.status === "closed" ||
                  complaint?.status === "cancel" ||
                  !complaint?.subsubCategory
                }
                selected
                value="Sub-Subcategory"
              >
                {!isPlant ? 12 : 11} . Type of Complaint
              </MenuItem>
            )}
            {isPlant && (
              <MenuItem
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  fontWeight: "bold",
                }}
                disabled={
                  !complaint?.vendor ||
                  complaint?.status === "resolved" ||
                  complaint?.status === "closed" ||
                  complaint?.status === "cancel"
                }
                selected
                value="Extend-deadline"
              >
                {!isPlant ? 13 : 12} . Extend Deadline
              </MenuItem>
            )}
          </Select>
        </Grid>
      </Paper>
    </Modal>
  );
};

export default UpdateLifecycleModal;
